.enumeration{
  background-color: $bright_gray;
  padding: rem(75) 0 rem(25);

  @media all and (min-width: 960px) {
    padding: 0 0 rem(60) ;
  }
    ul{

      li{
        display: flex;
        margin-bottom: rem(10);
        color: $primary_blue;

        font-size: rem(18);

        span{
          font-weight: bold;
          @media all and (max-width: 960px) {
            line-height: 120%;
          }
        }
        .number {
          $number_height: 80;

          @extend .d-flex;
          @extend .align-items-center;
          @extend .justify-content-center;

          letter-spacing: rem(0.4);
          background-color: $primary_blue;
          font-size: rem(36);

          color: $bright_gray;
          width: rem(68);
          height: rem($number_height);
          position:relative;
          flex-shrink:0;

          @media all and (min-width: 960px) {
            width: rem(85);
            padding-left: rem(24);
          }

          &:after{

            content:"";
            position:absolute;
            height:100%;
            right:rem(-10);
            top:0;
            border-bottom: rem($number_height/2) solid transparent;
            border-top: rem($number_height/2) solid transparent;
            border-left: rem(10) solid $primary_blue;

            @media all and (min-width: 960px) {

              right:rem(-14);
              border-left: rem(12) solid $primary_blue;
            }
          }
          @media all and (min-width: 960px) {
            &:before {
              content: "";
              position: absolute;
              width: rem(3);
              background: inherit;
              height:100%;
              right: rem(-2);
            }
          }
          + span{
            padding: 0 rem(40) 0 rem(40);
            @extend .d-flex;
            @extend .align-items-center;
          }
        }
        &:nth-last-child(1) {
          color: $primary_red;
          & .number {
            background-color: $primary_red;

            &:after {
              border-left-color: $primary_red;
            }
          }
        }
      }

    }

}