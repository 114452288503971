.video-mellerud {
    width: 100%;
    height: 100%;
  }
.video-wrapper{
    margin: 0 auto;
    max-height: 100%;
    height: rem(350);
    @media all and (min-width: 576px) {
        height: 21rem;
    }
    @media all and (min-width: 768px) {
        height: rem(480);
    }
    @media all and (min-width: 992px) {
        height: rem(480);
    }
    @media all and (min-width: 1200px) {
        height: rem(480);
    }
}