.overlay-video{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    margin: auto;
    max-width:100%;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(#00447e,0.97);
    text-align: center;
    display:none;
  
      iframe {
          width: 100%;
          height: 80%;
      }
  
    .wrapper-overlay{
      width: 95%;
        height: 100%;
      margin: 1rem auto;
      display:flex;
      flex-direction: column;
  
      .close{
        cursor:pointer;
        margin-bottom: rem(60);
      }
      h2{
        font-size: rem(24);
        font-weight: bold;
        color:#ffffff;
        margin-bottom: rem(24);
      }
      .share-icons{
        display: flex;
        margin-bottom: rem(50);
  
  
        .share-icon{
          width: rem(162);
          height: rem(162);
          margin: 0 5px;
          background-color: #f8f8f8;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          padding: rem(28) 0;
  
          .icon{
            width:rem(54);
  
            img{
              width: 100%;
            }
          }
          span{
            color:#505050;
            font-size: rem(18);
          }
        }
  
      }
      .share-fallback{
        padding:0 rem(5);
        p{
          font-size: rem(18);
          margin-bottom: rem(20);
          color: #ffffff;
        }
        input{
          height: rem(50);
          border: 1px solid #d3d3d3;
          width: 100%;
          color: #727272;
          font-size:rem(18);
          text-align:center;
          background-image: url('../img/klammer.jpg');
          background-repeat: no-repeat;
        }
      }
  
    }
  }