.tnb {
    padding: 70px 20px 0px 20px;
    max-width: 66.5rem;
    .header-headline {
        color: #E3000F;
        font-family: myriad-pro, sans-serif;
        font-size: rem(30);
        font-weight: bold;
        @media (min-width: 940px){
            margin-bottom: rem(45);
        }
    }

    .header-paragraph {
        width: 100%;
        font-family: myriad-pro, sans-serif;
        font-size: rem(24);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #00447e;
        margin-bottom: rem(15);
        @media (min-width: 940px){
            margin-bottom: rem(25);
            .paragraph-primary{

            }
        }
    }

    .header-paragraph-text {
        font-family: myriad-pro, sans-serif;
        font-size: rem(18);
        font-weight: 300;
        color: #727272;
        width: 100%;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: normal;
        margin-bottom: rem(43);
        @media (min-width: 940px){
            margin-bottom: rem(45);
        }
    }
    .header-paragraph-Sub-Headline {
        width: 100%;
        font-family: myriad-pro, sans-serif;
        font-size: rem(20);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #00447e;
        margin-bottom: rem(15);
        @media (min-width: 940px){
            margin-bottom: rem(45);
        }
    }

    .btn-primary {
        color: #00447e;
        background-color: transparent;
        width: 100%;
        height: rem(50);
        border: solid 2px #00447e;
        margin-bottom: rem(50);
        font-size: rem(20);
        font-family: myriad-pro, sans-serif;
        font-weight: bold;
        text-align: center;
        @media (min-width: 940px){
            margin-bottom: rem(45);
            width: 50%;
        }
    }
}
.link-gemeinsamwirken{
    color:#00447e;

}
.me-container{
    max-width: 66.5rem;
    margin:0 auto;
}
