.slider-wrapper {

  width: 100%;
  overflow: hidden;

  .slide-container {

    .slick-arrow{
      visibility: hidden;
      width:0;
      height:0;
      padding:0;
    display: block !important;
    }
    .item {
      background-color: $white;
      @media all and (min-width: 960px){
        margin-right:20px;
      }
      a {
        text-decoration: none;
        color: inherit;
      }

      .image-wrapper {
        width: 100%;
        max-width: 100%;

        img {
          display: block;
          margin: 0 auto;
          width: 100%;
        }
      }

      .text-content {
        height: rem(195);
        padding: 0 rem(30) rem(20);
        display: flex;
        flex-direction: column;
        @extend .justify-content-between;

        .description {
          .supplier {
            color: $primary_red;
            font-size: rem(16);
          }

          h5 {
            color: $primary_blue;
            font-size: rem(25);
            font-weight: $bold;
            letter-spacing: rem(0.2);
            margin-bottom: rem(5);
          }

          p {
            color: #000000;
            font-size: rem(18);
            font-weight: $small;
            line-height: rem(25);
          }
        }

        .properties {
          .price {
            font-size: rem(20);
            font-weight: $bold;
          }
        }
      }
    }

  }
}