.product_slider{
    width:100%;
    .danger{
        display: flex;
        padding: 10px 20px 0 10px;
        .danger-icon{
            justify-content: flex-start;
            display: flex;
            align-items: center;
            width: 6rem;
            @media all and (min-width: 500px){
                width: 3.75rem;
            }
            @media all and (min-width: 960px){
                width: 40px;
            }
        }
        p {
            color: #E3000F;
            font-size: 1rem;
            margin-bottom: 0;
            line-height: 1.3rem;
            @media all and (min-width: 960px){
                font-size: 0.875rem;
                padding-right: 20px;
                line-height: 1.125rem;
            }
        }
    }
    .left {
    @media all and (min-width: 960px){
      float:left;
      width: 41%;
      max-width: rem(413);
    }
        .image-bg {
          padding: rem(35) rem(20) rem(60);
          @media all and (min-width: 960px){
            padding: 40px 40px 45px;
          }
        }

        h3 {
          text-shadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.3);
          color: $bright_gray;
          font-weight: $bold;
          font-size: rem(36);
          line-height: 100%;
          margin-bottom: rem(30);
        }

        p {
          color: $bright_gray;
          text-shadow: 0 rem(2) rem(3) rgba(0, 0, 0, 0.4);
          font-size: rem(18);
          line-height: rem(27);
        }

        .product-teaser {
          .image-bg {
            @extend .d-flex;
            @extend .justify-content-between;
            background-image: url("../img/teaser_bg.jpg");
            background-size: cover;

            height: rem(500);
            flex-direction: column;

            @media all and (min-width: 960px){
              height: rem(526);
            }
          }
        }
    }
        .right{
          padding: rem(65) 0;
          @media all and (min-width: 960px){
            padding: 0 0 0 rem(20) ;
            width: 59%;
            max-width: rem(671);
            float:left;
          }
          h4{
            text-align: center;
            font-size: rem(28);
            font-weight: $bold;
            color: $primary_blue;
            padding:0 rem(20);
            margin-bottom: rem(35);
            @media all and (min-width: 960px){
              display:none;
            }
          }
          .slider{
            position:relative;
            padding: 0 rem(20);
            @media all and (min-width: 960px){
              padding: rem(12) 0;
            }
            .slider-arrow{
              position:absolute;
              z-index: 9;
              top:50%;
              &.arrow-left{
                left:rem(20);
                transform-origin: 50% 50%;
                transform: scaleX(-1);
                @media all and (min-width: 960px){
                  left:rem(0);
                }
              }
              &.arrow-right{
                right:rem(18);
              }

              .arrow{
                width: rem(40);
                height: rem(60);
                margin-top:-50%;
                background-color: $bright_gray;
                display: flex;
                cursor: pointer;
                @extend .justify-content-center;
                @extend .align-items-center;
              }
            }

          }
        }

        @media all and (min-width: 960px){
        h4.d-none{
          text-align: center;
          font-size: rem(28);
          font-weight: $bold;
          color: $primary_blue;
          padding:0 rem(20);
          display: block !important;
          margin: rem(65) auto rem(48);
        }
        .product_container {
          display: block;
          margin-left: auto;
          margin-right: auto;
          max-width: rem(1064);
          padding: 0 rem(20);
          width: 100%;
          height: rem(640);
        }
        }

    .col-12.image-bg:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.34) 12%, rgba(0,0,0,0) 70%);
        z-index: 0;
    }

    .top{
        position: relative;
    }
    .bottom{
        position: relative;
    }
}


