header.main {
  .branding {
    height: rem(40);
    width: 100%;
    background-color: $primary_red;
    position: relative;
    text-align: center;

    @media all and (min-width: 960px){
      height: rem(40);
      text-align: left;
    }

    .logo{
      margin:0 auto;
      display: inline-block;
      border-left: rem(3) solid $white;
      border-right: rem(3) solid $white;
      width: rem(146);

      @media all and (min-width: 960px){
        width:rem(216);
        margin-left:0;
        border-width:rem(10);
      }

      img{
        width:100%;
      }
    }
  }
  .header-image {
    height:rem(375);
    display:flex;
    background-color:#3f3f3f;
    max-width: 1840px;
    margin-left: auto;
    margin-right: auto;
    @extend .align-items-center;
    background-image: url('../img/key-visual-hintergrund-mobile.jpg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: cover;
    @media all and (min-width: 540px) {
      background-image: url('../img/visual.jpg');
      background-size: auto 150%;
    }
    @media all and (min-width: 960px) {
      height: rem(687);
    }

    .container{
      padding-bottom: rem(18);
      @media all and (min-width: 960px) {
        padding-left: rem(38);
        padding-top: rem(0);
      }

      h1, p {
        margin-bottom: 0;
        span{
          color: $white;
          line-height: rem(30);
          letter-spacing: rem(0.2);
          font-size:rem(18);
          font-weight:$bold;
          padding: 0 rem(6);
          display: inline-block;
          white-space: pre-wrap;
          margin-bottom: rem(4);

          @media all and (min-width: 960px){
            line-height: rem(54);
            font-size: rem(30);
            padding: 0 rem(12);
            margin-bottom: rem(8);
          }
        }

      }
      h1 span{
        background-color: $primary_red;
      }
      p span{
        background-color: $primary_blue;
      }
    }

  }


  @media all and (min-width: 960px) {

  }
}
