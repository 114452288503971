// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$primary_red: #E3000F;

$primary_blue: #00447E;
$secondary_blue: #00264B;



$black: #000000;
$dark: #505050;
$gray: #727272;
$bright_gray: #f8f8f8;
$white: #ffffff;

$ui_blue: #0D83BF;
$ui_green: #2ECC71;
$ui_red: #CC2936;
$ui_orange: #F18F01;

$small: 300;
$normal: 400;
$bold: 700;


$base-font-size: 16;