.teaser-section{
  position:relative;
}
.header-teaser{
  @extend .container;
  padding-top: rem(10);
  padding-bottom: 2.125rem;
  @media all and (min-width: 960px){
    padding-top: rem(0);
  }

  &:before{
    content:"";
    position:absolute;
    border-bottom: rem(30) solid $bright_gray;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    top: rem(-30);
    left:0;
    background-color: inherit;

    @media all and (min-width: 960px){
      border-bottom-width: rem(60);
      top: rem(-58);
    }
  }

   .col-12{
     @extend .align-items-center;
     @extend .text-center;
   }
   h2{
     font-size: rem(16);
     color: $dark;
     font-weight: $normal;
     margin-bottom:rem(16);
     @media all and (min-width: 960px){
       margin-bottom:rem(10);
     }

     .hashtag{
       color: $primary_red;
       font-weight: $bold;
       font-size: rem(30);
       @media all and (min-width: 960px){
         font-size: rem(60);
         line-height: rem(70);
       }
     }
   }
   p{
     font-size: rem(18);
     line-height: rem(25);
     font-weight: $small;
     color: $gray;
     margin-bottom: rem(20);

     @media all and (min-width: 960px) {
       font-size: rem(20);
       padding: 0;
       line-height: rem(28);
       margin-bottom: rem(35);
     }
   }
}
