.form-section {
  background-color: $white;
  text-align: center;
  padding: rem(30) 0 rem(45);
  @media all and (min-width: 960px) {
    padding: rem(80) 0 rem(150);
  }

  h2 {
    color: $primary_blue;
    font-size: rem(36);
    font-weight: $bold;
    margin-bottom: rem(30);
    @media all and (min-width: 960px) {
      margin-bottom: rem(20);
    }

  }

  @media all and (min-width: 960px) {
    .d-none {
      display: inline-block !important;
      max-width: rem(640);
      text-align: center;
      margin: 0 auto rem(60);
    }
  }
  .form-wrapper{
    margin-left: auto;
    margin-right: auto;
  }
  @media all and (min-width: 960px) {
    .form-wrapper {
      display: flex;
      width:100%;
      > div, form {
        flex-basis: 50%;
        padding: 0;
      }

      > div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .col-12{
          flex: 0 0 0;
        }
      }
      p {
        display: none;
      }
    }
  }

  .yt-icon {
    margin-bottom: rem(16);
    @media all and (min-width: 960px) {
      margin-bottom: rem(45);
    }
    div {
      width: rem(83);
      margin: 0 auto;
      @media all and (min-width: 960px) {
        width: rem(190);
      }
      img {
        width: 100%;
      }
    }
  }

  h3 {
    font-weight: $bold;
    color: $primary_red;
    font-size: rem(20);
    margin-bottom: rem(35);

    small {
      font-size: rem(18);
      font-weight: $small;
      color: $dark;
      display: block;
    }
  }

  p {
    text-align: left;
    font-size: rem(18);
    line-height: 1.5;
  }

  form {
    input {
      padding: 0 rem(13);
      height: rem(50);
      font-size: rem(18);
      font-style: italic;
      color: $gray;
      width: 100%;
      border: 1px solid #d3d3d3;
      margin-bottom: rem(10);
      @media all and (min-width: 960px) {
        margin-bottom: rem(5);
      }
    }

    input:not([type="checkbox"]) {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    input[type="checkbox"] {
      width: rem(16);
      height: rem(16);
      appearance: none;
      margin-bottom: 0;
      padding: 0;
      &:checked{
        background-image: url('../img/invalid-name.svg');
        background-size: 70% 70%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
    }

    div {
      display: flex;
      margin-top: rem(6);
    }

    label {
      font-size: rem(12);
      font-weight: $normal;
      padding-left: rem(10);
      margin-bottom: rem(45);
      margin-top: rem(-2);
    }

    button {
      margin: 0 auto;
      @media all and (min-width: 960px) {
        float: left;
      }
    }
    a, a u{
      color: $gray;
    }

    label{
      text-align: left;
    }

    @media all and (min-width: 960px) {
      > div {
        padding: 0 rem(10);
      }
      .half{
        width:50%;
        float: left;
        @media all and (min-width: 960px) {
          margin-bottom: rem(5);
        }
      }
    }
  }
}