.wall_container{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 66.5rem;
    width: 100%;

    h4{
        text-align: center;
        font-size: rem(28);
        font-weight: $bold;
        color: $primary_blue;
        padding:0 rem(20);
        margin-bottom: rem(35);

    }
}


