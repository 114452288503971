.voting--section{
    padding: 0 20px;

    .thumb-percent{
        margin: 0 0 0 0.5rem;
        padding: 0 0 0 .25rem;
    }

    .play-button{
        width: rem(60);
        height: rem(60);
    }
    .overlay-img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.3);
    }
    .z-index-btn{
        z-index: 1;
    }
    .thumb-btn{
        vertical-align: middle;
        border-style: none;
        margin-right: 0.625rem;
        padding-bottom: 0.3125rem;
    }

    .voting--description{
        h1{
            text-align: center !important;
            color: #00447E;
            font-size: 2.25rem;
            font-weight: 700;
            margin-bottom: 1.875rem;
        }
        p{
            text-align: center !important;
            font-size: 1.125rem;
            line-height: 1.5625rem;
            font-weight: 300;
            color: #727272;
            margin-bottom: 3.25rem;
        }
    }

    .voting--box{

        iframe {
            display: block;
            margin: auto;
            width: 100% ;
        }
        .inner {
            background: #ffffff;
            padding-bottom: 1rem;
            width: 100%;
            margin: 0 auto;
        }
        svg path {
            overflow: hidden;
            vertical-align: middle;
        }
        .bottom{
            margin-bottom: 10%;
            padding: 0 2.2rem;
        }
        .voting--counter{
            width: rem(110);
            height: rem(30);
            background: #00447E;
            margin: auto;
        }
        .voting--percent{
            color: #ffffff;
            font-size: rem(18);
            font-weight: 600;
            padding-left: .5rem;
        }
        .voting--team-inner{
            display:inline-block;
            text-align: left;
            width: 100%;
        }
        .btn-voting-width{
            max-width: 100%;
        }
        .voting--team{
            padding: 0 10%;
            margin:0;
            width:100%;
            text-align:center;
        }
        .video-img{
            width: 100%;
            cursor: pointer;
        }
        .video-thumbnail{
            background-size: cover;
        }
        .video-placeholder{
            width: 100%;
            padding-top: 20%;
            padding-bottom: 20%;
            position: relative;
            display: flex;
            justify-content: center;
        }
        .triangle-highlight{
            border-top: 0.625rem solid $primary_red;
            border-left: rem(57) solid transparent;
            border-right: rem(55) solid transparent;
            background-color: transparent;
        }
        .triangle {
            border-top: rem(10) solid #004484;
            border-left: rem(57) solid transparent;
            border-right: rem(55) solid transparent;
            background-color: transparent;
        }
        .team--title{
            font-weight: 700;
            color: #00447E;
            margin-top: rem(30);
            margin-bottom: 0;
            font-size: rem(24);
        }
        .team--city{
            color: #727272;
            margin-bottom: 1.1875rem;
        }
        .vote-checkmark{
            margin-right: .5rem ;
        }
        .highlight{
            background-color:  $primary_red;
        }
        .btn{
            text-align: center;
            white-space: nowrap;

            border-radius: 0;
            font-size: rem(16);
            font-weight: bold;
            //width: 80%;
            display: block;

            .thumb-btn {
                stroke: #E3000F;
            }

            &.btn-primary{
                background-color: $primary_red;
                border-color: $primary_red;
                color: $primary_red;
                margin-bottom: rem(10);
                &.voted{
                    background-color: $primary_red;
                    color: white;
                    .thumb-btn {
                        stroke: #FFFFFF;
                    }
                }
                &:hover{
                    background-color: $primary_red;
                    color: white;
                    .thumb-btn {
                        stroke: #FFFFFF;
                        transition: stroke 0.15s ease-in-out;
                    }
                }
            }
            &.btn-secondary{
                background-color: $primary_blue;
                border-color: $primary_blue;
                color: $primary_blue;
                margin-bottom: rem(40);
                &:hover{
                    background-color: rgba(0, 68, 126, 0.1);
                }
            }
            &.btn-outline{
                background-color: transparent;
                border-width:rem(3);
                border-style:solid;
            }
            &.btn-lg{
                padding: 0.6rem 1rem;
                font-size: rem(20);
            }
            img{
                vertical-align: middle;
                border-style: none;
                margin-right: rem(10);
                padding-bottom: rem(5);
            }
        }
    }
}


