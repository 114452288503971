footer {
  nav {
    text-align: center;
    background-color: $primary_blue;
    width: 100%;
    padding: rem(30) rem(20) rem(12);
    @media all and (min-width: 960px){
      padding: rem(60) rem(20) rem(70);
    }
    ul {
      display: inline;
      li {
        list-style-type: none;
        text-align: center;
        @media all and (min-width: 960px){
          display: inline-block;
          max-width: rem(335);
          width: 100%;
          margin: rem(10);
        }
        a {
          @extend button;
          text-align: center;
          font-size: rem(18);
          margin-bottom: rem(10);
          width: 100%;
          display: inline-block;
          @media all and (min-width: 960px){
           height: rem(50);
          }
        }
      }
    }

  }

  .social {
    width: 100%;
    max-width: rem(96);
    margin: 0 auto;
    padding: rem(32) 0 rem(16);

    ul {
      @extend .d-flex;
      @extend .align-items-center;

      li {
        @extend .d-flex;

        padding: 0 rem(6);

      }
      .fb,.yt{
        img{
          width: 100%;
        }
      }
      .fb{
        width: rem(34);
      }
      .yt{
        width: rem(38);
      }
    }
  }

  .copyright{
    border-top: 1px solid #d3d3d3;
    color: $gray;
    padding: rem(5);
    line-height: rem(16);
    span{
      display: block;
      text-align: center;
      font-size: rem(12);
      + span {
        font-size: rem(10)
      }
    }
  }
}