html {
  font-size: 4.27vw;
}
main{
  overflow: hidden;
}
@media all and (min-width: 640px){
  html {
    font-size: 16px;
  }
}
.max-wrapper{
  max-width: 1680px;
  margin-left:auto;
  margin-right: auto;
}
.container{
  padding:0 rem(20);
}
.reverse{
  display: flex;
  flex-direction: column-reverse;
}
@media all and (min-width: 960px) {
  .inner-wrapper {
    max-width: rem(680);
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px){
  .container, .header-teaser {
    max-width: 100%;
    padding:0 rem(20);
  }
}
.swal2-actions button{
  @extend button;
  background-color: $primary_red !important;
  border-radius:none;
  &:focus,&:hover{
    outline:0;
    border:0;
    box-shadow: none;
  }
}
.swal2-content{
  white-space: pre-line;
}
