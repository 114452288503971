a{
  color: #ffffff;
  text-decoration: none;
  &:hover{
    color:  #ffffff;
    text-decoration: none;
  }
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: none;
}

button{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border:0;
  width: 100%;
  font-size: rem(20);
  padding: rem(10) rem(0);
  font-weight: $bold;
  outline: 0;
  background-color: $primary_red;
  color: $bright_gray;
  cursor:pointer;
  &.shrink{
    padding: rem(10) rem(18);
  }

  &.share{
    color: $primary_blue;
    border: rem(2) solid $primary_blue;
    background-color:#ffffff;
    margin-top: rem(6) !important;
    @media screen and (min-width: 960px){
      margin-top:0 !important;
    }
    &:hover{
      background-color: rgba($primary_blue, 0.1);
    }
  }

  &.shrink{

    width: auto;
  }
  &:focus{
    outline: 0;
  }
  &:active,&:hover{
    background-color: #e8313d;
  }

  @media all and (min-width: 960px){
    max-width: rem(335);
    height:rem(60);
  }
}